.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 4vh;
  padding-top: 4vh;
}

.App-link {
  color: #15bbb4;
}

#tt {
  font-size: 20vw;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #15bbb4;
}

#stemby {
  color: #15bbb4;
}

#secondary {
  font-size: 23px;
}

#inv-btn {
  background-color: #15bbb4;
  border: none;
}

#tsparticles {
  height: 0vh;
}