.accordion-body {
    color: black;
    text-align: left;
    font-size: 15pt;
}

/* accordion colors */
.accordion-button {
    background-color: white !important;
    color: black;
}

.accordion-item {
    background-color: rgb(175, 217, 241);
}